// Account Registration Form
export const MAX_CHARACTER = {
  COMPANY_REGISTRATION_NUMBER:
    "Company Registration Number must be at most 20 characters",
  COMPANY_NAME: "Company Name must be at most 150 characters",
  ADDRESS: "Address must be at most 255 characters",
  CONTACT_NUMBER: "Contact Number must be at most 15 characters",
  REJECTION_REASON: "Rejection Reason must be at most 255 characters",
};

export const NUMERIC_VALIDATION = {
  CONTACT_NUMBER: "Contact Number must be numeric (allowed: 0-9)",
};

export const UPLOAD_FILE_VALIDATION = {
  TYPE: "Only .pdf, .doc, .docx, .jpg, .png, .jpeg, .xls, .xlsx, .ppt, .pptx files are allowed.",
  SIZE: "File size must be less than 25MB.",
};

export const UPLOAD_FILE_BULK_INVITATION_VALIDATION = {
  TYPE: "Only .csv files are allowed.",
};
