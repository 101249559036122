import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-2" }
const _hoisted_2 = { class: "w-40 px-4 py-8 rounded-lg shadow-lg flex flex-col items-center" }
const _hoisted_3 = { class: "text-file mb-2" }
const _hoisted_4 = { class: "text-sm font-semibold text-center text-cafe-gray truncate w-full mb-2" }
const _hoisted_5 = ["accept"]
const _hoisted_6 = { class: "flex flex-col space-y-1 mt-4 text-file" }
const _hoisted_7 = {
  key: 0,
  class: "mt-2 text-center w-40 text-neutral-black px-1 text-sm"
}
const _hoisted_8 = { class: "whitespace-normal" }

import { IFileMapObject } from "@/models/Company";
import { Document } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { computed, ref } from "vue";
import {
  UPLOAD_FILE_BULK_INVITATION_VALIDATION,
  UPLOAD_FILE_VALIDATION,
} from "@ems/constants/validation_form";
import { previewExtensions } from "@ems/constants/file";
import { downloadResource } from "@/utils/helpers/downloadDocument";


export default /*@__PURE__*/_defineComponent({
  __name: 'FileDisplay',
  props: {
  file: {
    type: Object as () => IFileMapObject,
  },
  fileLabel: {
    type: String,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isInvite: {
    type: Boolean,
    default: false,
  },
},
  emits: ["updateFile"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const fileInput = ref<HTMLInputElement | null>(null);

const viewFile = () => {
  if (props.file?.url) {
    window.open(props.file.url, "_blank");
  } else {
    if (props.file?.raw) {
      const blobUrl = URL.createObjectURL(props.file.raw);
      window.open(blobUrl, "_blank");
    } else {
      ElMessage.error("File URL not available");
    }
  }
};

const downloadFile = async () => {
  if (props.file?.url) {
    downloadResource(props.file.url, props.file.name);
  } else {
    if (props.file?.raw) {
      const link = document.createElement("a");
      const blobUrl = URL.createObjectURL(props.file.raw);
      link.href = blobUrl;
      link.download = props.file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      ElMessage.error("File URL not available for download");
    }
  }
};

const triggerFileUpload = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
};

const onFileSelected = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];

  if (file) {
    let isAllowedType =
      /\.(pdf|doc|docx|jpg|png|jpeg|xls|xlsx|ppt|pptx)$/i.test(file.name);
    if (props.isInvite) {
      isAllowedType = /\.(csv)$/i.test(file.name);
    }
    const isLessThan25MB = file.size / 1024 / 1024 < 25;

    if (!isAllowedType || !isLessThan25MB) {
      let errorMessage = !isAllowedType
        ? UPLOAD_FILE_VALIDATION.TYPE
        : UPLOAD_FILE_VALIDATION.SIZE;
      if (!isAllowedType && props.isInvite) {
        errorMessage = UPLOAD_FILE_BULK_INVITATION_VALIDATION.TYPE;
      }
      ElMessage.error(errorMessage);
      return;
    }

    const fileMapObject: IFileMapObject = {
      name: file.name,
      size: file.size,
      status: "ready",
      raw: file,
      uid: Date.now().toString(),
      url: URL.createObjectURL(file),
    };

    emit("updateFile", fileMapObject);
  }
};

const canPreviewFile = computed(() => {
  const fileExtension = props.file?.name?.split(".").pop()?.toLowerCase();
  return previewExtensions.includes(fileExtension || "");
});

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BaseSvgIcon, {
            class: "w-16 h-16 cursor-pointer",
            name: "document_new",
            onClick: _withModifiers(viewFile, ["prevent"])
          })
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(__props.file?.name), 1),
        _createElementVNode("input", {
          ref_key: "fileInput",
          ref: fileInput,
          type: "file",
          accept: 
            !__props.isInvite
              ? '.pdf,.doc,.docx,.jpg,.png,.jpeg,.xls,.xlsx,.ppt,.pptx'
              : '.csv'
          ,
          onChange: onFileSelected,
          class: "hidden"
        }, null, 40, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (canPreviewFile.value)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              content: "View",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  onClick: _withModifiers(viewFile, ["prevent"]),
                  class: "bg-white shadow-md rounded-md h-8 w-8 flex items-center justify-center"
                }, [
                  _createVNode(BaseSvgIcon, {
                    class: "w-4 h-4",
                    name: "preview"
                  })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_tooltip, {
          content: "Download",
          placement: "right"
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              onClick: _withModifiers(downloadFile, ["prevent"]),
              class: "bg-white shadow-md rounded-md h-8 w-8 flex items-center justify-center"
            }, [
              _createVNode(BaseSvgIcon, {
                class: "w-4 h-4",
                name: "download"
              })
            ])
          ]),
          _: 1
        }),
        (__props.isEdit)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 1,
              content: "Edit",
              placement: "right"
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  onClick: _withModifiers(triggerFileUpload, ["prevent"]),
                  size: "small",
                  class: "bg-white shadow-md rounded-md h-8 w-8 flex items-center justify-center"
                }, [
                  _createVNode(BaseSvgIcon, {
                    class: "w-3 h-3",
                    name: "edit_file"
                  })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (!__props.isEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(__props.fileLabel), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})